"use client";

import { sendEvent } from "@/analytics/event-logger";
import {
  type DirectDebitConsentFragment,
  type FullUserFragment,
  type MyBookedEventFragment,
  MyBookingsDocument,
  type MyBookingsQuery,
  MyDirectDebitDocument,
  type MyDirectDebitQuery,
  TrainingStatisticsDocument,
  type TrainingStatisticsQuery,
  UserDocument,
  type UserQuery,
  type UserWorkoutSummaryFragment,
} from "@/generated/client.generated";
import type { MyGroupActivityBookingTypes, ServicesBookingTypes } from "@/utils/booking-utils";
import { useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { type ReactNode, createContext, useEffect, useState } from "react";

interface UserContextProps {
  user: FullUserFragment | undefined;
  token: string | undefined;
  myBookedActivities: ReadonlyArray<MyGroupActivityBookingTypes>;
  myBookedServices: ReadonlyArray<ServicesBookingTypes>;
  myBookedEvents: ReadonlyArray<MyBookedEventFragment>;
  myTrainingStatistics: UserWorkoutSummaryFragment | undefined;
  refetchMyBookedActivities: () => void;
  refetchMyUser: () => void;
  bookingsLoading: boolean;
  bookingsHasBeenInitialised: boolean;
  isEmployee: boolean;
  hasActiveMembership: boolean;
  myDirectDebitConsents: ReadonlyArray<DirectDebitConsentFragment>;
  hasOtherPayer: boolean;
}

export const UserContext = createContext<UserContextProps>({
  bookingsHasBeenInitialised: false,
  bookingsLoading: false,
  hasActiveMembership: false,
  hasOtherPayer: false,
  isEmployee: false,
  myBookedActivities: [],
  myBookedEvents: [],
  myBookedServices: [],
  myDirectDebitConsents: [],
  myTrainingStatistics: undefined,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  refetchMyBookedActivities: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  refetchMyUser: () => {},
  token: undefined,
  user: undefined,
});

interface UserProviderProps {
  children?: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const session = useSession();
  const [user, setUser] = useState<FullUserFragment | undefined>();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const [token, setToken] = useState<string | undefined>(undefined);
  const [refetchMyBookedActivities, setRefetchMyBookedActivities] = useState<boolean>(false);
  const [refetchMyUser, setRefetchMyUser] = useState<boolean>(false);

  const isEmployee = session?.data?.user?.roles
    ? (session?.data?.user?.roles.some((role) => role === "ROLE_EMPLOYEE") ?? false)
    : false;
  const [myBookedActivities, setMyBookedActivities] = useState<ReadonlyArray<MyGroupActivityBookingTypes>>([]);
  const [myBookingsQuery, { loading: myBookingsLoading }] = useLazyQuery<MyBookingsQuery>(MyBookingsDocument);
  const [trainingStatisticsQuery] = useLazyQuery<TrainingStatisticsQuery>(TrainingStatisticsDocument);
  const [myDirectDebitConsentsQuery] = useLazyQuery<MyDirectDebitQuery>(MyDirectDebitDocument);

  const [myBookedServices, setMyBookedServices] = useState<ReadonlyArray<ServicesBookingTypes>>([]);
  const [myBookedEvents, setMyBookedEvents] = useState<ReadonlyArray<MyBookedEventFragment>>([]);
  const [myTrainingStatistics, setMyTrainingStatistics] = useState<UserWorkoutSummaryFragment | undefined>();
  const [myDirectDebitConsents, setMyDirectDebitConsents] = useState<ReadonlyArray<DirectDebitConsentFragment>>([]);

  const [bookingsLoading, setBookingsLoading] = useState<boolean>(false);
  const [bookingsHasBeenInitialised, setBookingsHasBeenInitialised] = useState<boolean>(false);

  const hasActiveMembership = (user?.subscriptions ?? []).length > 0;

  const [userQuery] = useLazyQuery<UserQuery>(UserDocument);

  useEffect(() => {
    if (myBookingsLoading) {
      setBookingsLoading(true);
    } else {
      setBookingsLoading(false);
    }
  }, [myBookingsLoading]);

  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      Sentry.setUser({ id: user.id });
      Sentry.setTag("subscription", user.subscriptions.length > 0 ? user.subscriptions[0].name : "no-subscription");
      Sentry.setTag("isEmployee", isEmployee);
      sendEvent({ has_active_membership: hasActiveMembership, is_employee: isEmployee, user_id: user.id });
      sendEvent({ algoliaUserToken: token });
    } else {
      sessionStorage.removeItem("user");
    }
  }, [user, isEmployee, hasActiveMembership, token]);

  useEffect(() => {
    if (session.status !== "loading") {
      if (session.status === "authenticated") {
        userQuery().then((userResponse) => {
          if (userResponse?.data?.User) {
            setUser(userResponse.data.User);
          }
        });
        setRefetchMyUser(false);
        if (session?.data.user?.access_token) {
          setToken(session.data.user.access_token);
        }
      } else {
        setUser(undefined);
        setToken(undefined);
      }
    }
  }, [session, userQuery, refetchMyUser]);

  useEffect(() => {
    if (refetchMyBookedActivities || myBookedActivities.length === 0) {
      if (session.status === "authenticated") {
        myBookingsQuery({ fetchPolicy: "network-only" }).then((myActivitiesResponse) => {
          setMyBookedActivities([...(myActivitiesResponse.data?.CustomerGroupActivityBookings ?? [])]);
          setMyBookedServices([...(myActivitiesResponse.data?.CustomerServiceBookings ?? [])]);
          setMyBookedEvents([...(myActivitiesResponse.data?.CustomerEventBookings ?? [])]);
          setBookingsHasBeenInitialised(true);
        });
      } else {
        setMyBookedActivities([]);
        setMyBookedServices([]);
        setMyBookedEvents([]);
      }
    }
    setRefetchMyBookedActivities(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.status, refetchMyBookedActivities]);

  useEffect(() => {
    if (session.status === "authenticated") {
      trainingStatisticsQuery({ fetchPolicy: "cache-first" }).then((trainingStatistics) => {
        setMyTrainingStatistics(trainingStatistics.data?.UserWorkoutSummary);
      });

      myDirectDebitConsentsQuery({ fetchPolicy: "cache-first" }).then((directDebitConsents) => {
        setMyDirectDebitConsents(directDebitConsents.data?.DirectDebitConsents ?? []);
      });
    } else {
      setMyTrainingStatistics(undefined);
      setMyDirectDebitConsents([]);
    }
  }, [session.status, trainingStatisticsQuery, myDirectDebitConsentsQuery]);

  return (
    <UserContext.Provider
      value={{
        bookingsHasBeenInitialised,
        bookingsLoading,
        hasActiveMembership,
        hasOtherPayer: user?.id !== user?.subscriptions[0]?.payer.id,
        isEmployee,
        myBookedActivities,
        myBookedEvents,
        myBookedServices,
        myDirectDebitConsents,
        myTrainingStatistics,
        refetchMyBookedActivities: () => setRefetchMyBookedActivities(true),
        refetchMyUser: () => setRefetchMyUser(true),
        token,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
