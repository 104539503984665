"use client";

import result from "@/generated/client.generated";
import { ApolloLink, createHttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import dayjs from "dayjs";
import type { Session } from "next-auth";
import { signOut, useSession } from "next-auth/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { type ReactNode, useEffect, useState } from "react";
import { errorLink, retryLink } from "./apollo-utils";

const backendHttpLink = () => {
  const url = process.env.NEXT_PUBLIC_BACKEND_URL || undefined;
  if (!url) throw "NEXT_PUBLIC_BACKEND_URL env variable not defined";
  return createHttpLink({ uri: url });
};

const makeClient = (session: Session | null) => () => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // Add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        authorization: session ? `Bearer ${session.user?.access_token}` : null,
        ...headers,
      },
    }));

    return forward(operation);
  });

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({ possibleTypes: result.possibleTypes }),
    connectToDevTools: process.env.NODE_ENV !== "production",
    link: ApolloLink.from([
      // in a SSR environment, if you use multipart features like
      // @defer, you need to decide how to handle these.
      // This strips all interfaces with a `@defer` directive from your queries.
      new SSRMultipartLink({ stripDefer: true }),
      retryLink,
      errorLink,
      authMiddleware,
      backendHttpLink(),
    ]),
  });
};
interface ApolloWrapperProps {
  session: Session | null;
  children: ReactNode;
}
export function ApolloWrapper({ session, children }: ApolloWrapperProps) {
  //Unsure if this is needed but ensure session always gets updated
  const [sessionState, setSessionState] = useState<Session | null>(session);
  const { data } = useSession();

  const pathName = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    // Logout user if token is expired
    if (session && session?.user?.expires && dayjs().isAfter(dayjs(session?.user?.expires))) {
      signOut({ callbackUrl: "/", redirect: true });
      // if (session.user?.refresh_token) {
      //   if (pathName === "/auth/refresh-token") {
      //     signOut({ callbackUrl: "/", redirect: true });
      //   } else {
      //     console.error("Token", session?.user);

      //     const encodedQueryString = encodeURIComponent(setQueryStringValues([], searchParams));
      //     const hasQueryString = encodedQueryString !== "";
      //     const currentUrl = `${ROOT_URL}${pathName}${hasQueryString ? "?" : ""}${encodedQueryString}`;
      //     console.error("Token expired, refreshing", session?.user?.expires);
      //     router.push(`/auth/refresh-token?callbackUrl=${currentUrl}`);
      //   }
      // }
    }
  }, [pathName, session, router, searchParams]);

  useEffect(() => {
    //update session
    setSessionState(session);
  }, [session, data?.expires]);

  return <ApolloNextAppProvider makeClient={makeClient(sessionState)}>{children}</ApolloNextAppProvider>;
}
