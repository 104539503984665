"use client";
import { SessionProvider } from "next-auth/react";
import type { ReactNode } from "react";

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthContext({ children }: AuthProviderProps) {
  // Re-fetch session every 5 minutes
  return <SessionProvider refetchInterval={15 * 60}>{children}</SessionProvider>;
}
